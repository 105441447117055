import * as React from "react";
import Layout from "../../components/Layout";
import ImageScroller from "../../ui-blocks/ImageScroller";

import empty_darker_png from "../../images/logo/empty-darker.png";
import empty_lighter_png from "../../images/logo/empty-lighter.png";

import Intro_Imagery_Header_Placeholder_jpeg from "../../images/1. Brand Elements/Intro_Imagery_Header_Placeholder.jpeg";
import Intro_Imagery_What_Makes_Us_ToyotaNZ_Placeholder_jpeg from "../../images/1. Brand Elements/Intro_Imagery_What_Makes_Us_ToyotaNZ_Placeholder.jpeg";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import intro_elements_A1_desktop_jpg from "../../images/tiles/intro_elements_A1_desktop.jpg";
import intro_elements_B1_svg from "../../images/tiles/intro_elements_B1.svg";
import Intro_Typography_svg from "../../images/tiles/Intro_Typography.svg";
import intro_elements_A2_desktop_jpg from "../../images/tiles/intro_elements_A2_desktop.jpg";
import intro_elements_A3_svg from "../../images/tiles/intro_elements_A3.svg";
import intro_elements_B3_desktop_jpg from "../../images/tiles/intro_elements_B3_desktop.jpg";
import { Link } from "gatsby";

const Introduction = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        style={{ backgroundColor: "rgb(241, 242, 243)", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#000", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "white", top: "-10%", textAlign: "left" }}
          >
            The Toyota Visual Identity System is a comprehensive guide designed
            to ensure all Toyota brand and product communications in New Zealand
            are unified, consistent and distinctly Toyota. Here, you’ll find
            everything you need to create your own Toyota marketing materials.
          </h1>
        </div>

        <div className="background" style={{ backgroundColor: "#fff" }}>
          <div style={{ height: "100%" }}>
            <img
              alt=""
              src={Intro_Imagery_Header_Placeholder_jpeg}
              alt="brand_header_desktop pic"
            />
          </div>
        </div>
      </div>
      <section
        className="component Section darker-background"
        id="tone-of-voice"
      >
        <div className="section-header">
          <div className="container">
            <h2 className="ui header">Tone of voice</h2>
          </div>
        </div>
        {/* <section className="blog-content darker-background" style={{paddingTop: '80px', paddingBottom: '80px'}}> */}
        <div
          className="blog-content darker-background"
          style={{ paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <div className="copy">
              <p>
                We're moving forward to create a greater New Zealand for
                everyone.
              </p>
              <p>
                We're doing this as an organisation through our three main brand
                journeys: Mobility, Sustainability and Society.
              </p>
            </div>
          </div>
        </div>
        {/* </section> */}

        {/* </section> */}

        {/* <section className='component Section' id='tone-of-voice'> */}

        <div
          className="page-header image-header"
          style={{ backgroundColor: "rgb(241, 242, 243)", height: "500px" }}
        >
          <div
            className="content-flex"
            style={{ color: "#000", top: "-10%", textAlign: "left" }}
          >
            <div style={{ width: "50%" }}></div>
            <div style={{ height: "100%" }}>
              <div style={{ height: "20%" }}></div>
              <div>
                <h1
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontFamily: "ToyotaType-Regular",
                    color: "rgb(235, 10, 30)",
                    fontWeight: "600",
                    fontSize: "40px",
                    textTransform: "uppercase",
                  }}
                >
                  Here's what makes us <br />
                  Toyota New Zealand
                </h1>
              </div>
            </div>
          </div>

          <div className="background" style={{ backgroundColor: "#fff" }}>
            <div style={{ height: "100%" }}>
              <img
                alt=""
                src={Intro_Imagery_What_Makes_Us_ToyotaNZ_Placeholder_jpeg}
                alt="brand_header_desktop pic"
              />
            </div>
          </div>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <h3 className="title">A guide to storytelling and story doing.</h3>
            <div className="copy">
              <p className="spaced-p">
                Toyota New Zealand is a well-loved brand with a well-established
                brand personality.
              </p>
              <p className="spaced-p">
                We have always been of New Zealand, and for New Zealand.
              </p>

              <p className="spaced-p">
                But as the face of New Zealand changes, with different
                attitudes, concerns and desires we must change and adapt too.
              </p>

              <p className="spaced-p">
                We need to use the right tone, in how we talk and how we portray
                ourselves to remain contemporary and relevant to the New Zealand
                of today, and tomorrow.
              </p>
              <p className="spaced-p">
                We want to not only be loved, but also desired.
              </p>
              <p className="spaced-p">
                We need to remain true to who we are, our values and purpose.
              </p>
              <p className="spaced-p">
                All the while as we move on with New Zealand.
              </p>
              <p className="spaced-p">
                Here are some thoughts on how we do that.
              </p>
            </div>
          </div>
        </div>

        <ImageScroller
          style={{
            backgroundColor: "white",
            paddingTop: "58px",
            paddingBottom: "73px",
            height: "900px",
          }}
          cardStyle={{ height: "786px" }}
          images={[
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>1. To start</h3>We put people first. We look to the needs, concerns and desires of New Zealanders and then align our brand and product stories with them to be relevant, current and meaningful.",
                style: { marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>2. To impress</h3>We find the innovation, design, technology, experience or solution in everything we offer, to reinforce our credentials as a forward-looking, mobility company and so continually demonstrate our relevance to today’s New Zealanders.",
                style: { marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>3. To connect</h3>We always look for the human connection. We are not progressive for progress sake. We are not a technology company. We are here to serve people by making mobility accessible to all on their personal journeys, both physical and emotional.",
                style: { marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "<b>Top right - preferred</b><br/>The logo can be anchored to the top of the artwork, using the 1X for the margin widths, as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>4. To engage</h3>We aim for hearts and minds. We use storytelling, humour, conversation, observations, characters with human and cultural truths to engage people. We tell stories that are contemporary and inclusive. We show mobility solutions and vehicles in the ‘real world’. They are not put up on a pedestal, but rather woven in as desirable features of New Zealand life.",
                style: { width: "800px", marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "When the logo can't sit anchored to the base of the artwork use the 1X for the margin widths as shown. ",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px; margin-bottom: 0'>5. To relate</h3>We talk to product features in a way that is relevant to customers. We don’t use jargon and overly technical language. We use communications to make the features of our products understandable, relatable and desirable to all New Zealanders. Our customers are our advocates. We respect them. We don’t talk down to them. We are honest and fair. Customers will more likely show loyalty to us if we show it to them.",
                style: { width: "800", marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "When the logo can't sit anchored to the top of the artwork use the 1X for the margin widths as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>6. To include</h3>We are inclusive and respectful of all points of views and celebrate the diversity and uniqueness of all New Zealanders. We speak from a diverse New Zealand perspective with a New Zealand voice. This can include the use of te reo Māori words or phrases when considered appropriate. This shouldn’t be forced or tokenism. But rather a reflection of the way te reo is more and more frequently being used as a natural part of our common daily New Zealand vernacular.",
                style: { width: "800", marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "<b>Bottom centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the base of the artwork than the 1X as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: empty_darker_png,
                style: { height: "500px", width: "800px" },
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>7. To sum up</h3>",
                style: { marginLeft: 0, marginRight: 0 },
              },
              // caption: {
              //     copy: "<b>Top centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the top of the artwork than the 1X as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
          ]}
        ></ImageScroller>
      </section>

      <section
        className="component Section darker-background"
        id="brand-elements"
        style={{ paddingBottom: "82px" }}
      >
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Brand Elements</h2>
          </div>
        </div>

        <div className="blog-content">
          <div className="container onecolumn">
            <h3>Brand visual tool kit</h3>
            <div className="copy">
              The Toyota Visual Identity System currently comprises eight core
              elements that help unify and communicate our brand with
              consistency and clarity.
            </div>
            <br />
            <div className="copy">
              They include photography, logos, typography, colour, the{" "}
              <em>Let's Go Places</em> tagline, TVC endframe, Messaging Platform
              and Brand Campaigns.
            </div>
          </div>
        </div>
      </section>

      <div className="full-width" style={{ margin: "80px 0 80px 0" }}>
        {/* here need to have a block of 4 images in a row, two rows */}
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "inline-flex",
              height: "285px",
              width: "1140px",
              maxHeight: "285px",
              overflow: "clip",
              margin: "0 auto 0 auto",
            }}
          >
            <Link to="/brandguidelines/logo" className="linkOverlay tile">
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      className="linkTitle"
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Logo
                    </p>
                    <p
                      className="linkActionCopy"
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_A1_desktop_jpg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>

            <Link to="/brandguidelines/tagline" className="linkOverlay tile">
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                {/* <div style={{position: 'absolute', top: '76px', left: '66px', minWidth:'151px'}}> */}
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Tagline
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_B1_svg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>
            <Link to="/brandguidelines/typography" className="linkOverlay tile">
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Typography
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                        color: "white",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={Intro_Typography_svg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>

            <Link
              to="/brandguidelines/photography"
              className="linkOverlay tile"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Photography
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_A2_desktop_jpg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* second row  */}
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "inline-flex",
              height: "285px",
              width: "1140px",
              maxHeight: "285px",
              overflow: "clip",
              margin: "0 auto 0 auto",
            }}
          >
            <Link to="/brandguidelines/colours" className="linkOverlay tile">
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Colours
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                        color: "white",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_A3_svg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>

            <Link
              to="/brandguidelines/tvc-endframe"
              className="linkOverlay tile"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      TVC Endframe
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_B3_desktop_jpg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>

            <Link
              to="/brandguidelines/messaging-platform"
              className="linkOverlay tile"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Messaging Platform
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_B3_desktop_jpg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>

            <Link
              to="/brandguidelines/brand-campaigns"
              className="linkOverlay tile"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "285px",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", display: "flex" }}>
                  <div style={{ margin: "auto" }} className="hoverCopy">
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Brand Campaigns
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      See more
                    </p>
                  </div>
                  <picture
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  >
                    <img
                      alt=""
                      src={intro_elements_B3_desktop_jpg}
                      style={{ width: "285px" }}
                    />
                  </picture>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <PrevNextNavFooter
        nextText="Next"
        nextHeader="Logo"
        nextUrl="/brandguidelines/logo"
      />
    </Layout>
  );
};

export default Introduction;
